import './globals.css';
import Header from './components/Header.jsx'
import Section from "./components/Section.jsx"
import { ReactComponent as Title } from './components/svg/title.svg';
import appScreen1 from './components/static/appScreen1.png';
import appScreen2 from './components/static/appScreen2.png';
import appScreen3 from './components/static/appScreen3.png';
import TextSection from './components/TextSection.jsx';
import ImageSection from './components/ImageSection.jsx';
import ImageSectionLast from './components/ImageSectionLast.jsx';

function App() {
  return (
    <>
      <Header />
      {/* <MobileOverlay /> */}
      <Section>{/*First page */}
        <div className='w-full h-full flex flex-col justify-center items-center'>
          <Title className="max-w-xl my-8 -mt-6 md:mt " width="100%" />
          <p className='text-center text-xl md:text-2xl'>Scan your skin and get personalized skincare guidance and product recommendations.</p>
          <p className='text-center text-xl md:text-2xl'>No more wasted time, no more wasted money, no more skin worries.</p>
          <p className='text-center text-xl md:text-2xl'><b>Make skincare easy for yourself.</b></p>
        </div>
      </Section>
      <ImageSection 
        title="Take a skan"
        description="Reimagine your skincare experience by getting accurate analyses of your skin"
        image={appScreen1}
      />
      <TextSection 
        line1="9 in 10 women"
        line2="experience frustration in finding skincare that works for them"
        line3="Make skincare easy for yourself."
      />
      <ImageSection 
        title="Personalized Recommendations"
        description="Get personalized recommendations catered to your skin"
        image={appScreen2}
      />
      <TextSection 
        line1="2.8 months"
        line2="the average time spent trying new skincare products for results"
        line3="Save your time."
      />
      <ImageSectionLast 
        title="Track your progress"
        description="No more being unsure about how your products are working. Track your skincare journey and make real progress."
        image={appScreen3}
      />
      <div className='w-full h-[100px]'></div>
    </>
  );
}

export default App;
