import React from 'react';

import { ReactComponent as SkanLogoSVG } from './svg/skan.svg';
import { ReactComponent as DownloadButtonSVG } from './svg/download.svg';
const app_link = "https://apps.apple.com/ca/app/skan-ai-skincare-and-beauty/id6449196562";

const handleDownloadClick = () => { //Event Listener for Download Button Tracking
  window.gtag('event', 'download_click', {
    event_category: 'Download',
    event_label: 'App Download',
    transport_type: 'beacon' 
  });
};

function Header() {
  return (
    <>
      <div className="fixed top-[50px] w-full px-[50px] flex flex-row justify-center md:justify-start">
        <SkanLogoSVG height="46px" width="144px" />
      </div>
      <div className="fixed bottom-[61px] md:bottom-[unset] md:top-[50px] w-full px-[50px] flex flex-row justify-center md:justify-end">
        <a id= "downloadButton" className='bg-primary px-[20px] py-[10px] rounded-full md:px-[20px] md:py-[10px]' href={app_link} onClick = {handleDownloadClick}>
          <DownloadButtonSVG className = 'h-[28.5px] w-[166.5px] md:h-[38px] md:w-[222px]' />
        </a>
      </div>
    </>
  );
}

export default Header;
