import Section from './Section'

const ImageSectionLast = ({ title, description, image }) => {
    return (
        <Section>{/*Fifth Page */}
            <div className='w-full h-full flex flex-col md:flex-row justify-center'>
                <div className='flex flex-col justify-center items-center md:items-start'>
                    <h1 className='text-4xl font-bold text-center md:text-left'>{title}</h1>
                    <p className='max-w-lg text-xl text-center md:text-2xl md:text-left'>
                        {description}
                    </p>
                </div>
                <img className="mb-16 min-h-0 object-contain md:mb-[unset]" src={image} alt="" />
            </div>
            <div className='flex flex-row justify-center space-x-4 pt-20'>
                <a href="https://app.termly.io/document/privacy-policy/6bbf7848-09a8-45c9-aa42-52948ea4bb9c" class="modal-link text-center text-sm md:text-base">Privacy Policy</a>
                <a href="https://app.termly.io/document/terms-of-service/cfe3517e-a1f5-430c-935e-6279bbb934cb" class="modal-link text-center text-sm md:text-base">Terms and Conditions</a>
                <a href="mailto:support@skinai.co" class = "text-center text-sm md:text-base">Contact Us</a>
            </div>
        </Section>
    )
}

export default ImageSectionLast