import Section from './Section'

const ImageSection = ({ title, description, image }) => {
    return (
        <Section>{/*Fifth Page */}
            <div className='w-full h-full flex flex-col md:flex-row justify-center'>
                <div className='flex flex-col justify-center items-center md:items-start'>
                    <h1 className='text-4xl font-bold text-center md:text-left'>{title}</h1>
                    <p className='max-w-lg text-xl text-center md:text-2xl md:text-left'>
                        {description}
                    </p>
                </div>
                <img className="mb-16 min-h-0 object-contain md:mb-[unset]" src={image} alt="" />
            </div>
        </Section>
    )
}

export default ImageSection