import React from 'react'

const Section = ({ children }) => {
  return (
    <div className="h-screen snap-always snap-start p-4 py-32 flex flex-col items-center">
      {/* restrict content size */}
      <div className='w-full h-full max-w-screen-lg '>
        {children}
      </div>
    </div>
  )
}

export default Section