import Section from './Section'

const TextSection = ({line1, line2, line3}) => {
    return (
        <Section>
            <div className='w-full h-full flex flex-col justify-center items-center'>
                <h1 className='text-center font-bold text-4xl my-4'>{line1}</h1>
                <h3 className='text-center text-4xl my-4'>{line2}</h3>
                <h3 className='text-center font-bold text-4xl my-4'>{line3}</h3>
            </div>
        </Section>
    )
}

export default TextSection